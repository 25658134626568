import React, { Component } from 'react';
import { Link, graphql } from "gatsby";
import AOS from 'aos';

import PrimaryLayout from "../layouts/PrimaryLayout"
import SEO from '../layouts/SEO';


class blogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: 4,
      items: 0,
      loadingState: false
    };

    this.loadMore = this.loadMore.bind(this);
    this.myRef = React.createRef();
  }

  loadMore() {    
    this.setState({ loadingState: true });
    setTimeout(() => {
      this.setState((prev) => {
        return {visible: prev.visible + 15, loadingState: false};
      });
      if (typeof window !== 'undefined' && this.state.visible < this.state.items ) {
        const height = document.getElementsByTagName('article')[0].clientHeight;
        window.scrollTo({behavior: 'smooth', top: this.myRef.current.offsetTop - (height * 16)})
      }
    }, 100);
  }

  componentDidMount(){
    this.setState({
      items : this.props.data.allWordpressPost.nodes.length
    });
    if (typeof window !== 'undefined') {
      AOS.init({ duration : 800});
    }
    

    // this.refs.iScroll.addEventListener("scroll", () => {
    //   if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >=this.refs.iScroll.scrollHeight){
    //     this.loadMore();
    //   }
    // });
  }

  render() {
    const post = this.props.data.allWordpressPost.nodes;
    const page = this.props.data.wordpressPage;
    return (
      <PrimaryLayout>
        <SEO 
          title={page.acf.seo_title}
          description={page.acf.seo_description}
          keywords={page.acf.seo_keywords}
        />
        <section id="primary" className="content-area">
          <main id="main" className="site-main">
            <div className="container-fluid">
              <div className="row p-0 m-0">
                <div className="container main-container-padding-t-5 main-container-padding-b-2">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="header-text-container">
                        <div className="vertical-center">
                          <h1 data-aos="fade-up" className="mb-md-4 mb-3" dangerouslySetInnerHTML={{ __html : page.acf.page_heading}} />
                          <h3 data-aos="fade-up" dangerouslySetInnerHTML={{ __html : page.acf.page_content}} />
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img
                        data-src="https://storage.googleapis.com/alkye.com/1/2020/06/blog-1.jpg"
                        className="header-image lazyload"
                        alt="banner"
                         data-aos="fade-up"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div className="blogposts">
                {post.slice(0, this.state.visible).map((node, index) => (
                  <article key={index}
                    className="post type-post status-publish format-standard has-post-thumbnail hentry category-marketing category-sales category-social-media tag-brand-awareness tag-branding tag-customer-experience tag-customer-persona tag-social-media tag-social-media-branding tag-social-media-marketing entry"
                  >
                    <div className="container-fluid primary-color-bg  ">
                      <div className="container p-2">
                        <div className="row main-container-padding-b-1 main-container-padding-t-1">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12 text-left georgia-text">
                                <h2 data-aos="fade-up" data-aos-delay="0.1s" className="article-page-heading" dangerouslySetInnerHTML={{__html: node.title}} />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 text-left pb-2">
                                <p data-aos="fade-up" data-aos-delay="0.2s" className="card-title text-primary-color text-lowercase secondary-font-medium m-0 ">
                                  <span className="reduced-letter-spacing">{node.categories[0].name}</span>{" "}
                                  |{" "}
                                  <small className="text-muted primary-font captalise georgia-text ">
                                    <time dateTime="2020-09-14T06:42:41+00:00">
                                    {node.date}
                                    </time>
                                  </small>
                                </p>
                              </div>
                            </div>

                            <div data-aos="fade-up" data-aos-delay="0.3s" className="entry-content text-primary-color" dangerouslySetInnerHTML={{ __html : node.excerpt }}></div>

                            <div className="row">
                              <div data-aos="fade-up" data-aos-delay="0.4s" className="col-md-12">
                                <Link
                                  to={'/' + node.slug + '/'}
                                  className="button-primary-gray"
                                >
                                  Read More&nbsp;&nbsp;
                                  <span>
                                    <i className="la la-angle-right"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </article>
                ))}
              </div>

              {/* Load More */}
              {this.state.visible < this.state.items &&
              <div className="load_more text-center py-5 bg-white">
                <button onClick={this.loadMore} ref={this.myRef} className="btn btn-loadmore" type="button">{this.state.loadingState ? 'loading More Items..' : 'Load More'}</button>
              </div>}
          </main>
        </section>
      </PrimaryLayout>
    );
  }
}

export default blogs;


export const query = graphql`
{
  wordpressPage(wordpress_id: {eq: 94}) {
    acf {
      seo_title
      seo_keywords
      seo_description
      page_heading
      page_content
    }
  }
  allWordpressPost {
    nodes {
      excerpt
      date(formatString: "MMMM D, Y")
      title
      categories {
        name
      }
      slug
    }
  }
}
`